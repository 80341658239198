const jwt = require('jsonwebtoken');
const dotenv = require('dotenv');
dotenv.config();
import store from '../store/index';
import router from '../router/index';

const MAX_DELAY_FOR_JWT_REFRESH =
  (process.env.VUE_APP_JWT_DURATION / 2) * 24 * 60 * 60 * 1000;

function disconnectUser(reason) {
  store.dispatch('resetUser');
  router.push({ name: 'disconnected', params: { reason } });
}

function checkJWT(token) {
  var decoded = jwt.decode(token, { complete: true });
  const expDate = new Date(decoded.payload.exp * 1000);
  const minDateForRefresh = new Date(
    expDate.getTime() - MAX_DELAY_FOR_JWT_REFRESH
  );
  const now = Date.now();
  if (now > minDateForRefresh && now < expDate) {
    store.dispatch('refreshToken');
  }
}

export { disconnectUser, checkJWT };
