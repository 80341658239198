<template>
  <div class="baby-text-area">
    <h3 class="text-light">Mon compte</h3>
    <br />
    <b-tabs content-class="mt-3">
      <b-tab title="Infos personnelles" active>
        <b-row>
          <b-col sm="8" md="6" lg="4" class="mx-auto">
            <b-form @submit.prevent="handleSaveAccountChanges()">
              <b-form-group id="input-group-firstname" label="Prénom" label-for="input-firstname">
                <b-form-input
                  id="input-firstname"
                  type="text"
                  v-model="firstname"
                  :state="firstnameState"
                  required
                  placeholder="Saisissez votre prénom"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-lastname" label="Nom" label-for="input-lastname">
                <b-form-input
                  id="input-lastname"
                  v-model="lastname"
                  type="text"
                  :state="lastnameState"
                  required
                  placeholder="Saisissez votre nom"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-email" label="Email" label-for="input-email">
                <b-form-input
                  id="input-email"
                  v-model="email"
                  type="email"
                  :state="emailState"
                  required
                  placeholder="Saisissez votre adresse email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-username"
                label="Identifiant"
                label-for="input-username"
              >
                <b-form-input
                  id="input-username"
                  v-model="username"
                  type="text"
                  :state="usernameState"
                  required
                  placeholder="Saisissez un identifiant"
                ></b-form-input>
              </b-form-group>
              <br />
              <b-form-row>
                <div class="mx-auto">
                  <b-button
                    type="reset"
                    variant="secondary"
                    class="mx-1"
                    @click.prevent="handleCancelAccountChanges()"
                    :disabled="!isModified"
                  >Annuler</b-button>
                  <b-button
                    type="submit"
                    name="button"
                    variant="info"
                    class="mx-1"
                    :disabled="!isModified"
                  >Valider</b-button>
                </div>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Modifier mon mot de passe">
        <b-row>
          <b-col sm="8" md="6" lg="4" class="mx-auto">
            <b-form @submit.prevent="handleChangePassword()">
              <b-form-group
                id="input-group-password"
                label="Mot de passe"
                label-for="input-password"
              >
                <b-form-input
                  id="input-password"
                  v-model="password"
                  type="password"
                  :state="passwordState"
                  required
                  placeholder="Saisissez un mot passe"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-confirmedPassword"
                label="Confirmation"
                label-for="input-confirmedPassword"
              >
                <b-form-input
                  id="input-confirmedPassword"
                  v-model="confirmedPassword"
                  type="password"
                  :state="confirmedPasswordState"
                  required
                  placeholder="Confirmez le mot de passe"
                ></b-form-input>
              </b-form-group>
              <b-form-row>
                <div class="mx-auto">
                  <b-button
                    type="reset"
                    variant="secondary"
                    class="mx-1"
                    @click.prevent="handleCancelChangePassword()"
                  >Annuler</b-button>
                  <b-button type="submit" name="button" variant="info" class="mx-1">Valider</b-button>
                </div>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-modal
      id="modal-save-ok"
      hide-header
      ok-only
      centered
      body-bg-variant="success"
      footer-bg-variant="success"
      button-size="sm"
    >
      <p>
        <strong>Modification sauvegardée.</strong>
      </p>
    </b-modal>
    <b-modal
      id="modal-save-error"
      hide-header
      ok-only
      centered
      body-bg-variant="danger"
      footer-bg-variant="danger"
      button-size="sm"
    >
      <p>
        <strong>Une erreur s'est produite !</strong>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { authComputed } from '../store/helpers.js';
import UserService from '@/service/UserService';

export default {
  name: 'myaccount',
  data() {
    return {
      password: '',
      confirmedPassword: '',
      firstname: this.$store.state.user.userData.first_name,
      lastname: this.$store.state.user.userData.last_name,
      email: this.$store.state.user.userData.email,
      username: this.$store.state.user.userData.user_name
    };
  },
  computed: {
    ...authComputed,
    passwordState() {
      if (this.password === '') return null;
      return this.password.length > 7 ? true : false;
    },
    confirmedPasswordState() {
      if (this.confirmedPassword === '') return null;
      return this.password == this.confirmedPassword ? true : false;
    },
    firstnameState() {
      if (!this.isModified || this.firstname === '') return null;
      return this.firstname.length > 2 ? true : false;
    },
    lastnameState() {
      if (!this.isModified || this.lastname === '') return null;
      return this.lastname.length > 2 ? true : false;
    },
    emailState() {
      if (!this.isModified || this.email === '') return null;
      return this.email.length > 2 ? true : false;
    },
    usernameState() {
      if (!this.isModified || this.username === '') return null;
      return this.username.length > 2 ? true : false;
    },
    isModified() {
      return (
        this.firstname !== this.$store.state.user.userData.first_name ||
        this.lastname !== this.$store.state.user.userData.last_name ||
        this.email !== this.$store.state.user.userData.email ||
        this.username !== this.$store.state.user.userData.user_name
      );
    }
  },
  created() {
    // this.firstname = userData.first_name;
  },
  methods: {
    handleCancelAccountChanges() {
      this.password = '';
      this.confirmedPassword = '';
    },
    handleSaveAccountChanges() {
      let userInfo = {
        id: this.userData.id,
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        user_name: this.username
      };
      UserService.updateInfo(userInfo)
        .then(() => {
          this.$bvModal.show('modal-save-ok');
        })
        .catch(() => {
          this.$bvModal.show('modal-save-error');
        });
    },
    handleCancelChangePassword() {
      this.password = '';
      this.confirmedPassword = '';
    },
    handleChangePassword() {
      let pwd = {
        id: this.userData.id,
        password: this.password,
        confirmedPassword: this.confirmedPassword
      };
      UserService.changePassword(pwd)
        .then(() => {
          this.$bvModal.show('modal-save-ok');
        })
        .catch(() => {
          this.$bvModal.show('modal-save-error');
        });
    }
  }
};
</script>

<style scoped></style>
