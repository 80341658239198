<template>
  <div class="wishList">
    <b-row class="text-light">
      <b-col sm="12">
        <h2 class="text-center">
          <span class="icon icon-gift"></span>
          Liste de Noël
          <span class="icon icon-gift"></span>
        </h2>
        <br />
        <h5 class="text-center text-warning">
          Noël approche et les enfants ont envoyé leur liste au Père Noël...
          <br />
          Si vous souhaitez nous offrir un objet de cette liste, pensez à le
          réserver avant de l'acheter, afin d'éviter des doublons.
        </h5>
        <p class="text-center">
          <br />Si vous souhaitez faire livrer les objets directement chez nous
          pour faciliter le trajet des vacances, re-voici notre adresse :
          <br />124 rue des arbousiers 40150 ANGRESSE
          <br />
          <br />Merci d'avance pour les bébés choux !
          <span class="icon icon-big icon-baby-happy"></span>&nbsp;
          <span class="icon icon-big icon-baby-love"></span>
        </p>
      </b-col>
    </b-row>
    <br />
    <br />
    <b-row>
      <WishListItem
        v-for="item in items"
        :key="item.id"
        :item="item"
        @openItem="openItem(item)"
        class="clickable"
      />
    </b-row>

    <b-modal
      header-bg-variant="info"
      :body-bg-variant="showedItem.available ? 'light' : 'secondary'"
      :header-border-variant="showedItem.available ? 'light' : 'secondary'"
      :footer-border-variant="showedItem.available ? 'light' : 'secondary'"
      header-text-variant="light"
      id="modal-show-item"
      :title="showedItem.title"
      centered
      content-class="shadow"
      size="lg"
    >
      <b-row>
        <b-col>
          <img
            :src="showedItem.image"
            style="max-height: 30rem; max-width: 30rem;"
            class="rounded mx-auto"
          />
        </b-col>
        <b-col>
          <h3>
            <b-badge>{{ showedItemPrice }}</b-badge>
          </h3>
          <br />
          <br />
          <h5
            v-if="
              showedItem.description !== null && showedItem.description !== ''
            "
          >
            Description :
          </h5>
          <p>{{ showedItem.description }}</p>
          <br />
          <br />
          <h5 v-if="showedItem.bought_by != null">
            <b-badge variant="warning">{{
              'Déjà acheté par ' + showedItemBuyer
            }}</b-badge>
          </h5>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            v-if="showedItem.available"
            type="submit"
            name="button"
            variant="info"
            class="mx-1 float-right"
            @click="confirmBuyItem()"
            >Réserver</b-button
          >
          <b-button :href="showedItem.url" target="_blank" class="float-right"
            >Lien</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-confirm-buy" hide-header centered @ok="buyItem()">
      <template v-slot:modal-ok>Valider</template>
      <template v-slot:modal-cancel>Annuler</template>
      <p>
        Allez-vous vraiment acheter l'objet
        <strong>{{ this.showedItem.title }}</strong> ?
      </p>
      <p>
        <i>Si vous confirmez, il sera affiché comme "acheté" sur le site.</i>
      </p>
    </b-modal>
    <b-modal
      id="modal-buy-ok"
      hide-header
      ok-only
      centered
      body-bg-variant="success"
      footer-bg-variant="success"
      button-size="sm"
    >
      <p>
        <strong>L'objet a bien été réservé.</strong>
      </p>
      <p>
        Il vous reste maintenant à acheter l'objet via notre lien (ou ailleurs,
        si vous trouvez).
      </p>
      <p>
        Merci !&nbsp;&nbsp;
        <span class="icon icon-baby-love"></span>
        <span class="icon icon-baby-kiss"></span>
      </p>
      <p align="right">
        <i>Hélène & Clément</i>
      </p>
    </b-modal>
    <b-modal
      id="modal-buy-error"
      hide-header
      ok-only
      centered
      body-bg-variant="warning"
      footer-bg-variant="warning"
      button-size="sm"
    >
      <p>Une erreur s'est produite.</p>
      <p>
        Erreur :
        <i>{{ this.errorMessage }}</i>
      </p>
    </b-modal>
  </div>
</template>

<script>
import WishListService from '@/service/WishListService';
import WishListItem from '@/components/WishListItem';
import { authComputed } from '../store/helpers';

export default {
  name: 'WishList',
  components: {
    WishListItem
  },
  data() {
    return {
      items: [],
      showedItemId: null,
      showedItem: {
        url: '',
        title: '',
        description: '',
        image: '',
        price: null
      },
      errorMessage: ''
    };
  },
  computed: {
    ...authComputed,
    showedItemPrice() {
      if (this.showedItem.price === null) return '--,-- €';
      let cur = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      });
      return cur.format(this.showedItem.price);
    },
    showedItemBuyer() {
      if (this.showedItem.bought_by) {
        return (
          this.showedItem.bought_by.first_name +
          ' ' +
          this.showedItem.bought_by.last_name
        );
      }
      return null;
    }
  },
  created() {
    this.refreshList();
  },
  methods: {
    refreshList() {
      WishListService.getChristmasList(this.userData.id).then(response => {
        this.items = response.data.data;
        if (this.showedItemId !== null) {
          this.showedItem = this.items.find(i => i.id == this.showedItemId);
        }
      });
    },
    confirmBuyItem() {
      this.$bvModal.show('modal-confirm-buy');
    },
    buyItem() {
      WishListService.buyItem(this.showedItem.id, this.userData.id)
        .then(response => {
          let result = response.data;
          if (result.success) {
            this.refreshList();
            this.$bvModal.show('modal-buy-ok');
          } else {
            this.errorMessage = result.message;
            this.$bvModal.show('modal-buy-error');
          }
        })
        .catch(err => {
          this.errorMessage = err.response.data.message;
          this.$bvModal.show('modal-buy-error');
        });
    },
    openItem(item) {
      this.showedItemId = item.id;
      this.showedItem = item;
      this.$bvModal.show('modal-show-item');
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
