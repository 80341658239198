<template>
  <b-container class="homeNotLoggedIn">
    <b-row class="h-100">
      <b-col
        sm="12"
        md="10"
        lg="8"
        class="m-auto baby-text-area border border-primary"
      >
        <p>
          <img src="/img/logo_v2.png" />
        </p>
        <br />
        <p class="text-danger" v-if="message.length > 0">
          <strong>{{ message }}</strong>
        </p>
        <b-button
          id="show-btn"
          @click="$bvModal.show('modal-login')"
          variant="primary"
          >Connexion</b-button
        >
        <b-modal
          header-bg-variant="info"
          header-text-variant="light"
          id="modal-login"
          title="Connexion"
          centered
          hide-footer
          content-class="shadow"
        >
          <Login
            @cancelClicked="$bvModal.hide('modal-login')"
            @forgotPwdClicked="forgotPassword()"
          />
        </b-modal>
        <br />
        <br />
        <b-button
          id="show-btn"
          @click="$bvModal.show('modal-new-user')"
          variant="info"
          >Créer mon compte</b-button
        >
        <b-modal
          header-bg-variant="info"
          header-text-variant="light"
          id="modal-new-user"
          title="Créer mon compte"
          centered
          hide-footer
          content-class="shadow"
        >
          <RegisterUser
            @cancelClicked="$bvModal.hide('modal-new-user')"
            @registered="registered()"
          />
        </b-modal>
      </b-col>
    </b-row>
    <b-modal
      id="modal-registered"
      hide-header
      ok-only
      centered
      body-bg-variant="success"
      footer-bg-variant="success"
      button-size="sm"
    >
      <p>
        <strong>Votre compte a été créé.</strong>
      </p>
      <p>
        Il est désormais en attente de validation. Surveillez votre boîte email
        !
      </p>
      <p>
        A bientôt !&nbsp;&nbsp;
        <span class="icon icon-baby-smile"></span>
      </p>
      <p align="right">
        <i>Hélène & Clément</i>
      </p>
    </b-modal>
    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      id="modal-forgotpwd"
      title="Mot de passe oublié"
      centered
      hide-footer
      content-class="shadow"
      ><ForgotPassword
        @forgotPasswordOk="forgotPasswordOk()"
        @forgotPasswordError="forgotPasswordError()"
    /></b-modal>
    <b-modal
      id="modal-resetpwd"
      hide-header
      ok-only
      centered
      body-bg-variant="success"
      footer-bg-variant="success"
      button-size="sm"
    >
      <p>
        <strong>Email envoyé !</strong>
      </p>
      <p>
        Vérifiez votre boîte de réception ...
      </p>
    </b-modal>
    <b-modal
      id="modal-resetpwderror"
      hide-header
      ok-only
      centered
      body-bg-variant="danger"
      footer-bg-variant="danger"
      button-size="sm"
    >
      <p>
        <strong>Adresse email inconnu !</strong>
      </p>
    </b-modal>
  </b-container>
</template>

<script>
import Login from '@/components/Login.vue';
import RegisterUser from '@/components/RegisterUser.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';

export default {
  name: 'homeNotLoggedIn',
  props: {
    reason: { type: String, default: '' }
  },
  components: {
    Login,
    RegisterUser,
    ForgotPassword
  },
  computed: {
    message() {
      if (this.reason)
        return this.reason === 'TOKEN_EXPIRED'
          ? 'Votre session a expiré. Veuillez vous re-connecter.'
          : "Une erreur s'est produite. Veuillez vous re-connecter.";
      return '';
    }
  },
  methods: {
    registered() {
      this.$bvModal.hide('modal-new-user');
      this.$bvModal.show('modal-registered');
    },
    forgotPassword() {
      this.$bvModal.hide('modal-login');
      this.$bvModal.show('modal-forgotpwd');
    },
    forgotPasswordOk() {
      this.$bvModal.hide('modal-forgotpwd');
      this.$bvModal.show('modal-resetpwd');
    },
    forgotPasswordError() {
      this.$bvModal.hide('modal-forgotpwd');
      this.$bvModal.show('modal-resetpwderror');
    }
  }
};
</script>

<style scoped>
.homeNotLoggedIn {
  height: 100%;
  text-align: center;
}
</style>
