<template>
  <b-container class="home">
    <b-row>
      <b-col sm="8" class="mx-auto welcome-message">
        <p>
          <img src="/img/logo_v2.png" />
        </p>
        <br />
        <p class="text-light">OH OH OH !!! C'est bientôt Noël ...</p>
        <br />
        <b-img :src="require('@/assets/christmas.png')" fluid alt="C'est bientôt Noël ..."></b-img>
      </b-col>
    </b-row>
    <br />
  </b-container>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      news: []
    };
  }
};
</script>

<style scoped>
.home {
  height: 100%;
}
.welcome-message {
  text-align: center;
}
</style>
