import { postToWishlistService } from './apiClient';

export default {
  getChristmasList(userId, recipient) {
    return postToWishlistService('/listitems', {
      userId,
      version: 3,
      recipient
    });
  },
  getList(userId, version = 2) {
    return postToWishlistService('/listitems', { userId, version });
  },
  updateItem(itemInfo) {
    return postToWishlistService('/updateitem', itemInfo);
  },
  updateOrder(items) {
    return postToWishlistService('/updateorder', { items });
  },
  buyItem(itemId, buyerId) {
    return postToWishlistService('/buyitem', { itemId, buyerId });
  }
};
