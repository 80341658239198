import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  created() {
    const userString = localStorage.getItem('user'); // grab user data from local storage
    if (userString) {
      // check to see if there is indeed a user
      const user = JSON.parse(userString); // parse user data into JSON
      this.$store.commit('SET_USER', user);
    }
  },
  render: h => h(App)
}).$mount('#app');
