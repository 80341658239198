<template>
  <b-col align="center" style="min-width: 17rem;">
    <b-card
        no-body
        tag="item"
        style="height: 23rem;width: 15rem;"
        class="mb-2"
        @click="openItem()"
        :bg-variant="item.available ? 'light' : 'secondary'"
    >
      <div class="item-image-area">
        <b-card-img
            v-if="item.image"
            :src="item.image"
            :alt="item.title"
            style="max-height: 15rem; max-width: 15rem;"
            class="rounded-5 item-image"
        ></b-card-img>
        <b-card-img
            v-else
            :src="require('@/assets/gift.png')"
            :alt="item.title"
            style="max-height: 15rem; max-width: 15rem;"
            class="rounded-5 item-image"
        ></b-card-img>
        <b-badge
            variant="danger" v-if="item.recipient" class="ml-2"
            style="position: absolute; top: 0; left: 0; z-index: 1000;">
          Pour {{ item.recipient }}
        </b-badge>
      </div>
      <b-card-body class="p-2">
        <b-card-text>
          <strong>{{ item.title }}</strong>
        </b-card-text>
      </b-card-body>
      <b-row>
        <b-col>
          <h6>
            <b-badge variant="warning" v-if="item.bought_by" class="ml-2">
              <i>Déjà acheté</i>
            </b-badge>
            <b-badge v-else-if="item.shop" variant="info" class="ml-2">
              <i>{{ item.shop }}</i>
            </b-badge>
          </h6>
        </b-col>
        <b-col>
          <h6>
            <b-badge v-if="item">{{ price }}</b-badge>
          </h6>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
export default {
  name: 'WishListItem',
  props: {
    item: Object,
  },
  computed: {
    price() {
      if (!this.item.price) return '--,-- €';
      let cur = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      });
      return cur.format(this.item.price);
    },
  },
  methods: {
    openItem() {
      this.$emit('openItem', this);
    },
  },
};
</script>

<style scoped>
.item-image-area {
  min-height: 15rem;
}

.item-image-area:hover .item-action-buttons {
  opacity: 1;
}

.item-action-buttons {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.35s ease;
}
</style>
