import { postToAuthService } from './apiClient';

export default {
  register(credentials) {
    return postToAuthService('/register', credentials);
  },
  forgotPassword(userEmail) {
    return postToAuthService('/forgotPassword', { userEmail });
  },
  resetPassword(pwdObject) {
    return postToAuthService('/resetPassword', pwdObject);
  }
};
