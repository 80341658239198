<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container fluid>
      <b-form @submit.prevent="login()">
        <b-form-group
          id="input-group-username"
          label="Identifiant"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="username"
            type="text"
            required
            placeholder="Saisissez votre identifiant"
            :disabled="loading"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-password"
          label="Mot de passe"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            v-model="password"
            :type="passwordInputType"
            required
            placeholder="Saisissez votre mot passe"
            :disabled="loading"
          ></b-form-input
          ><b-form-checkbox
            id="checkbox-password"
            v-model="showPassword"
            name="checkbox-password"
            :disabled="loading"
          >
            Afficher mot de passe
          </b-form-checkbox></b-form-group
        >
        <b-form-row>
          <p class="text-danger text-center w-100">{{ errorMessage }}</p>
        </b-form-row>
        <b-form-row>
          <div class="ml-auto">
            <b-button
              type="reset"
              variant="secondary"
              class="mx-1"
              @click.prevent="handleCancel()"
              :disabled="loading"
              >Annuler</b-button
            >
            <b-button
              type="submit"
              name="button"
              variant="info"
              class="mx-1"
              :disabled="loading"
              >Se connecter</b-button
            >
          </div> </b-form-row
        ><b-form-row>
          <b-button
            type="reset"
            variant="link"
            class="mx-1"
            @click.prevent="handleForgotPwd()"
            :disabled="loading"
            >Mot de passe oublié</b-button
          ></b-form-row
        >
      </b-form>
    </b-container></b-overlay
  >
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      showPassword: false,
      loading: false
    };
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch('login', {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$router.push({ name: 'home' });
        })
        .catch(err => {
          this.errorMessage = err.response.data.error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$emit('cancelClicked');
    },
    handleForgotPwd() {
      this.$emit('forgotPwdClicked');
    }
  }
};
</script>

<style lang="scss" scoped></style>
