import {
  postToUserService,
  putToUserService,
  deleteToUserService
} from './apiClient';

export default {
  getList() {
    return postToUserService('/list', {});
  },
  getUserData(userId) {
    return postToUserService('/getUserData', { userId, includeLogs: true });
  },
  getScores() {
    return postToUserService('/scores', {});
  },
  updateList(users) {
    return postToUserService('/updatelist', users);
  },
  getPermissionList() {
    return postToUserService('/listpermissions', {});
  },
  changePassword(pwdObject) {
    return postToUserService('/changepassword', pwdObject);
  },
  updateInfo(userInfo) {
    return postToUserService('/update', userInfo);
  },
  activate(userId) {
    return postToUserService('/activate', { userId });
  },
  deactivate(userId) {
    return postToUserService('/deactivate', { userId });
  },
  getTags() {
    return postToUserService('/tags');
  },
  addTag(userId, tag) {
    return putToUserService('/tag', { userId, tag });
  },
  removeTag(userId, tag) {
    return deleteToUserService('/tag', { userId, tag });
  }
};
