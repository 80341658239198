<template>
  <div id="app" style="height:100%">
    <div v-if="loggedIn" style="height:100%">
      <b-navbar toggleable="sm" type="dark" variant="info">
        <b-navbar-brand>
          <img src="/img/logo_v2.png" height="30" class="align-top" />
          Bebe-chou.fr
        </b-navbar-brand>
        <span v-if="ENV !== 'PROD'" class="ml-auto mr-2 badge badge-warning">
          {{ ENV }}
        </span>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-if="loggedIn" :to="{ name: 'home' }" exact>
              <span class="icon icon-home"></span> Accueil
            </b-nav-item>
            <b-nav-item v-if="accessToGames" :to="{ name: 'christmasList' }">
              <span class="icon icon-gift"></span> Liste de Noël
            </b-nav-item>
            <b-nav-item-dropdown v-if="false">
              <template v-slot:button-content>
                <em>Archives</em>
              </template>
              <b-dropdown-item v-if="accessToGames" :to="{ name: 'gameHome' }">
                <span class="icon icon-pacman"></span> Jeux
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'photos' }">
                <span class="icon icon-images"></span> Photos
              </b-dropdown-item>
              <b-dropdown-item v-if="loggedIn" :to="{ name: 'wishlist' }" exact>
                <span class="icon icon-gift"></span> Liste de naissance
              </b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>
          <b-navbar-nav v-if="loggedIn" class="ml-auto">
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <em>
                  <span class="icon icon-baby-smile"></span>
                  {{ userData ? userData.first_name : '' }}
                </em>
              </template>
              <b-dropdown-item v-if="isAdmin" :to="{ name: 'dashboard' }">
                <span class="icon icon-table"></span>
                Tableau de bord
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'myaccount' }">
                <span class="icon icon-user"></span>
                Mon compte </b-dropdown-item
              ><b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="logout">
                <span class="icon icon-logout"></span>
                Déconnexion </b-dropdown-item
              ><b-dropdown-divider></b-dropdown-divider
              ><b-dropdown-item disabled class="text-center"
                >v{{ version }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-row no-gutters>
        <b-col sm="12" xl="10" class="mx-auto">
          <br />
          <router-view />
        </b-col>
      </b-row>
    </div>
    <div v-else style="height:100%">
      <router-view />
    </div>
  </div>
</template>

<script>
import { authComputed } from './store/helpers.js';
import { version } from '../package.json';

export default {
  data() {
    return {
      ENV: process.env.VUE_APP_ENV
    };
  },
  computed: {
    ...authComputed,
    accessToGames() {
      return (
        this.loggedIn &&
        this.userData &&
        this.userData.games &&
        this.userData.games > 0
      );
    },
    version() {
      return version;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    }
  }
};
</script>

<style>
#app {
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
