<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container>
      <b-row align-v="center">
        <b-col sm="12" class="mx-auto">
          <b-form @submit.prevent="register()">
            <b-form-group
              id="input-group-firstname"
              label="Prénom"
              label-for="input-firstname"
            >
              <b-form-input
                id="input-firstname"
                v-model="firstname"
                type="text"
                :state="firstnameState"
                required
                placeholder="Saisissez votre prénom"
                :disabled="loading"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback"
                >Enter at least 3 letters</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              id="input-group-lastname"
              label="Nom"
              label-for="input-lastname"
            >
              <b-form-input
                id="input-lastname"
                v-model="lastname"
                type="text"
                :state="lastnameState"
                required
                placeholder="Saisissez votre nom"
                :disabled="loading"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-email"
              label="Email"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                v-model="email"
                type="email"
                :state="emailState"
                required
                placeholder="Saisissez votre adresse email"
                :disabled="loading"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-username"
              label="Identifiant"
              label-for="input-username"
            >
              <b-form-input
                id="input-username"
                v-model="username"
                type="text"
                :state="usernameState"
                required
                placeholder="Saisissez un identifiant"
                :disabled="loading"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-password"
              label="Mot de passe"
              label-for="input-password"
            >
              <b-form-input
                id="input-password"
                v-model="password"
                :type="passwordInputType"
                :state="passwordState"
                required
                placeholder="Saisissez un mot passe"
                :disabled="loading"
              ></b-form-input
              ><b-form-checkbox
                id="checkbox-password"
                v-model="showPassword"
                name="checkbox-password"
                :disabled="loading"
              >
                Afficher mot de passe
              </b-form-checkbox></b-form-group
            ><b-form-row>
              <p class="text-danger text-center w-100">{{ errorMessage }}</p>
            </b-form-row>
            <b-form-row>
              <div class="ml-auto">
                <b-button
                  type="reset"
                  variant="secondary"
                  class="mx-1"
                  @click.prevent="handleCancel()"
                  :disabled="loading"
                  >Annuler</b-button
                >
                <b-button
                  type="submit"
                  name="button"
                  variant="info"
                  class="mx-1"
                  :disabled="loading"
                  >Créer mon compte</b-button
                >
              </div>
            </b-form-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container></b-overlay
  >
</template>

<script>
import AuthService from '@/service/AuthService';

export default {
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      username: '',
      password: '',
      errorMessage: '',
      showPassword: false,
      loading: false
    };
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? 'text' : 'password';
    },
    firstnameState() {
      if (this.firstname === '') return null;
      return this.firstname.length > 2 ? true : false;
    },
    lastnameState() {
      if (this.lastname === '') return null;
      return this.lastname.length > 2 ? true : false;
    },
    emailState() {
      if (this.email === '') return null;
      return this.email.length > 2 ? true : false;
    },
    usernameState() {
      if (this.username === '') return null;
      return this.username.length > 2 ? true : false;
    },
    passwordState() {
      if (this.password === '') return null;
      return this.password.length > 2 ? true : false;
    }
  },
  methods: {
    register() {
      this.loading = true;
      AuthService.register({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        username: this.username,
        password: this.password
      })
        .then(() => {
          this.registerDone();
        })
        .catch(err => {
          this.errorMessage = err.response.data.error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    registerDone() {
      this.$emit('registered');
    },
    handleCancel() {
      this.$emit('cancelClicked');
    }
  }
};
</script>

<style lang="scss" scoped></style>
