<template>
  <div class="baby-text-area">
    <h3>Tableau de bord</h3>
    <br />
    <b-tabs content-class="mt-3">
      <b-tab title="Utilisateurs" active>
        <UserAdmin
          :users="userList"
          :allTags="tags"
          @refreshAsked="refreshData"
        />
      </b-tab>
      <b-tab title="Jeux & Accès">
        <GameAccessAdmin
          :users="userList"
          :allTags="tags"
          @refreshAsked="refreshData"
        />
        <hr />
        <GameSolutionAdmin />
      </b-tab>
      <b-tab title="Liste de Noël">
        <WishListAdmin />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import UserAdmin from '@/components/admin/UserAdmin.vue';
import GameAccessAdmin from '@/components/admin/GameAccessAdmin.vue';
import GameSolutionAdmin from '@/components/admin/GameSolutionAdmin.vue';
import WishListAdmin from '@/components/admin/WishListAdmin.vue';
import UserService from '@/service/UserService';

export default {
  name: 'dashboard',
  components: {
    UserAdmin,
    GameAccessAdmin,
    GameSolutionAdmin,
    WishListAdmin
  },
  data() {
    return {
      userList: [],
      tags: []
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getUserList();
      this.getTags();
    },
    getUserList() {
      UserService.getList().then(response => {
        this.userList = response.data.data;
      });
    },
    getTags() {
      UserService.getTags().then(res => {
        this.tags = res.data.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
