<template>
  <b-container class="homeNotLoggedIn">
    <b-row class="h-100">
      <b-col
        sm="12"
        md="10"
        lg="8"
        class="m-auto baby-text-area border border-primary"
      >
        <p>
          <img src="/img/logo_v2.png" />
        </p>
        <br />
        <br />
        <h3>Veuillez saisir votre nouveau mot de passe :</h3>
        <br />
        <b-form @submit.prevent="handleChangePassword()">
          <b-form-group
            id="input-group-password"
            label="Mot de passe"
            label-for="input-password"
          >
            <b-form-input
              id="input-password"
              v-model="password"
              type="password"
              :state="passwordState"
              required
              placeholder="Saisissez un mot passe"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-confirmedPassword"
            label="Confirmation"
            label-for="input-confirmedPassword"
          >
            <b-form-input
              id="input-confirmedPassword"
              v-model="confirmedPassword"
              type="password"
              :state="confirmedPasswordState"
              required
              placeholder="Confirmez le mot de passe"
            ></b-form-input>
          </b-form-group>
          <b-form-row>
            <div class="mx-auto">
              <b-button
                type="reset"
                variant="secondary"
                class="mx-1"
                @click.prevent="handleCancelChangePassword()"
                >Annuler</b-button
              >
              <b-button type="submit" name="button" variant="info" class="mx-1"
                >Valider</b-button
              >
            </div>
          </b-form-row></b-form
        ></b-col
      ></b-row
    >
    <b-modal
      id="modal-save-ok"
      hide-header
      ok-only
      centered
      body-bg-variant="success"
      footer-bg-variant="success"
      button-size="sm"
    >
      <p>
        <strong>Votre mot de passe a été modifié.</strong>
      </p>
      <p>
        Vous pouvez maintenant vous connecter au site.
      </p>
      <p>
        <b-button
          type="submit"
          name="button"
          variant="info"
          class="mx-1"
          href="/"
          >bebe-chou.fr</b-button
        >
      </p>
    </b-modal>
    <b-modal
      id="modal-save-error"
      hide-header
      ok-only
      centered
      body-bg-variant="danger"
      footer-bg-variant="danger"
      button-size="sm"
    >
      <p>
        <strong>Une erreur s'est produite !</strong>
      </p>
    </b-modal>
  </b-container>
</template>

<script>
import AuthService from '@/service/AuthService';

export default {
  name: 'resetPassword',
  props: {
    token: { type: String, required: true }
  },
  data() {
    return {
      password: '',
      confirmedPassword: ''
    };
  },
  computed: {
    passwordState() {
      if (this.password === '') return null;
      return this.password.length > 7 ? true : false;
    },
    confirmedPasswordState() {
      if (this.confirmedPassword === '') return null;
      return this.password == this.confirmedPassword ? true : false;
    }
  },
  components: {},
  methods: {
    handleCancelChangePassword() {
      this.password = '';
      this.confirmedPassword = '';
    },
    handleChangePassword() {
      let pwd = {
        token: this.token,
        password: this.password,
        confirmedPassword: this.confirmedPassword
      };
      AuthService.resetPassword(pwd)
        .then(() => {
          this.$bvModal.show('modal-save-ok');
        })
        .catch(() => {
          this.$bvModal.show('modal-save-error');
        });
    }
  }
};
</script>

<style scoped>
.homeNotLoggedIn {
  height: 100%;
  text-align: center;
}
</style>
