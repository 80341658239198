import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null
  },
  getters: {
    loggedIn(state) {
      return !!state.user;
    },
    isAdmin(state) {
      return !!state.user && !!state.user.userData && state.user.userData.admin;
    },
    userData(state) {
      return state.user ? state.user.userData : null;
    },
    jwt(state) {
      return state.user ? state.user.token : null;
    }
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    },
    SET_TOKEN(state, token) {
      state.user.token = token;
      localStorage.setItem('user', JSON.stringify(state.user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    SET_USER_DATA(state, userData) {
      state.user.userData = userData;
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    LOGOUT() {
      localStorage.removeItem('user');
      location.replace('/');
    },
    RESET_USER(state) {
      state.user = null;
      localStorage.removeItem('user');
    }
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/auth/login', credentials)
        .then(({ data }) => {
          commit('SET_USER', data);
        });
    },
    refreshToken({ commit }) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/user/refreshToken')
        .then(({ data }) => {
          commit('SET_TOKEN', data.token);
        });
    },
    refreshUserData({ commit }, userId) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/user/getUserData', {
          userId
        })
        .then(({ data }) => {
          commit('SET_USER_DATA', data);
        });
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    resetUser({ commit }) {
      commit('RESET_USER');
    }
  },
  modules: {}
});
