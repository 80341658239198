var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"ml-auto",staticStyle:{"z-index":"1"}},[_c('b-button',{staticClass:"ml-1",attrs:{"id":"btn-full-view","size":"sm","variant":"info"},on:{"click":function($event){_vm.showInactive = !_vm.showInactive}}},[_vm._v(_vm._s(_vm.showInactive ? 'Masquer les comptes inactifs' : 'Afficher les comptes inactifs'))]),_c('b-button',{staticClass:"ml-1",attrs:{"id":"btn-full-view","size":"sm","variant":"info"},on:{"click":function($event){_vm.showAllFields = !_vm.showAllFields}}},[_vm._v(_vm._s(_vm.showAllFields ? 'Affichage simple' : 'Affichage complet'))]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasModifications),expression:"hasModifications"}],staticClass:"ml-1",attrs:{"id":"btn-order-done","size":"sm","variant":"warning"},on:{"click":_vm.saveModifications}},[_vm._v("Sauvegarder les modifications")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasModifications),expression:"hasModifications"}],staticClass:"ml-1",attrs:{"id":"btn-order-cancel","size":"sm","variant":"secondary"},on:{"click":_vm.cancelModifications}},[_vm._v("Annuler les modifications")])],1),_c('br'),_c('br'),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.filteredUsers,"fields":_vm.fields,"tbody-tr-class":_vm.rowClass},on:{"row-clicked":_vm.selectUser},scopedSlots:_vm._u([{key:"cell(active)",fn:function(data){return [_c('span',{class:data.value
            ? 'icon icon-checkbox-checked'
            : 'icon icon-checkbox-unchecked'})]}},{key:"cell(admin)",fn:function(data){return [_c('span',{class:data.value
            ? 'icon icon-checkbox-checked'
            : 'icon icon-checkbox-unchecked'})]}},{key:"cell(date_last_connected)",fn:function(data){return [_vm._v(" "+_vm._s(data.value !== null && data.value !== '' ? new Date(data.value).toLocaleString('fr-FR') : '')+" ")]}},{key:"cell(date_last_action)",fn:function(data){return [_vm._v(" "+_vm._s(data.value !== null && data.value !== '' ? new Date(data.value).toLocaleString('fr-FR') : '')+" ")]}},{key:"cell(logs)",fn:function(data){return _vm._l((data.value),function(action,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(new Date(action.date).toLocaleString('fr-FR'))+" : "+_vm._s(action.action)+" ")])})}},{key:"cell(tags)",fn:function(row){return [_c('b-form-tags',{attrs:{"input-id":"tags-basic"},on:{"input":function($event){return _vm.updateTags(row.item.id, row.value)}},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})]}},{key:"cell(actions)",fn:function(row){return [(row.item.active)?_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.deActivateUser(row.item.id)}}},[_vm._v("Désactiver")]):_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.activateUser(row.item.id)}}},[_vm._v("Activer")])]}}])}),_c('b-modal',{attrs:{"id":"modal-selected-user","title":_vm.selectedUser?.first_name + ' ' + _vm.selectedUser?.last_name,"centered":"","hide-footer":"","content-class":"shadow","header-bg-variant":_vm.selectedUser?.active ? 'info' : 'secondary'}},[_c('b-overlay',{attrs:{"show":_vm.loadingUser,"rounded":"sm"}},[_c('b-container',[_c('p',[_vm._v("ID : "+_vm._s(_vm.selectedUser?.id))]),_c('p',[_vm._v("Email : "+_vm._s(_vm.selectedUser?.email))]),_c('p',[_vm._v("Username : "+_vm._s(_vm.selectedUser?.user_name))]),_c('p',[_vm._v(" Actif : "),_c('span',{class:_vm.selectedUser?.active
                ? 'icon icon-checkbox-checked'
                : 'icon icon-checkbox-unchecked'})]),_c('p',[_vm._v(" Admin : "),_c('span',{class:_vm.selectedUser?.admin
                ? 'icon icon-checkbox-checked'
                : 'icon icon-checkbox-unchecked'})]),_c('p',[_vm._v(" Dernière connexion : "+_vm._s(_vm.selectedUser?.date_last_connected ? new Date(_vm.selectedUser?.date_last_connected).toLocaleString( 'fr-FR' ) : '')+" ")]),_c('p',[_vm._v("Dernières actions :")]),_c('ul',_vm._l((_vm.selectedUser?.logs),function(action,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(new Date(action.date).toLocaleString('fr-FR'))+" : "+_vm._s(action.label)+" ")])}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }