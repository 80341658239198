<template>
  <b-overlay :show="loading" rounded="sm">
    <b-container fluid>
      <b-form @submit.prevent="forgotPassword()">
        <b-form-group
          id="input-group-email"
          label="Email"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="email"
            type="email"
            required
            placeholder="Saisissez votre email"
            :disabled="loading"
          ></b-form-input>
        </b-form-group>
        <b-form-row>
          <p class="text-danger text-center w-100">{{ errorMessage }}</p>
        </b-form-row>
        <b-form-row>
          <div class="ml-auto">
            <b-button
              type="submit"
              name="button"
              variant="info"
              class="mx-1"
              :disabled="loading"
              >Réinitialiser</b-button
            >
          </div>
        </b-form-row>
      </b-form>
    </b-container></b-overlay
  >
</template>

<script>
import AuthService from '@/service/AuthService';

export default {
  data() {
    return {
      email: '',
      errorMessage: '',
      loading: false
    };
  },
  methods: {
    forgotPassword() {
      this.loading = true;
      AuthService.forgotPassword(this.email)
        .then(() => {
          this.email = '';
          this.$router.push({ name: 'home' });
          this.$emit('forgotPasswordOk');
        })
        .catch(err => {
          this.errorMessage = err.response.data.error;
          this.$emit('forgotPasswordError');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
