import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
// import Game from '../views/Game.vue';
// import GameV2 from '../views/GameV2.vue';
// import WishList from '../views/WishList.vue';
import ChristmasList from '../views/ChristmasList.vue';
// import Photos from '../views/Photos.vue';
// import RegisterUser from '../views/RegisterUser.vue';
import Dashboard from '../views/Dashboard.vue';
import MyAccount from '../views/MyAccount.vue';
import HomeNotLoggedIn from '../views/HomeNotLoggedIn.vue';
// import GameHome from '../views/GameHome.vue';
// import GameHomeV2 from '../views/GameHomeV2.vue';
import ResetPassword from '../views/ResetPassword.vue';
const { checkJWT } = require('../service/authUtils');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'ext',
    component: HomeNotLoggedIn,
    meta: {
      guest: true
    }
  },
  {
    path: '/',
    name: 'disconnected',
    component: HomeNotLoggedIn,
    props: true,
    meta: {
      guest: true
    }
  },
  {
    path: '/accueil',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/jeux/archive',
  //   name: 'gameHomeArchive',
  //   component: GameHome,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/jeux',
  //   name: 'gameHome',
  //   component: GameHomeV2,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/jeu/:number',
  //   name: 'game',
  //   props(route) {
  //     const props = { ...route.params };
  //     props.number = +props.number;
  //     props.id = props.number + 17;
  //     return props;
  //   },
  //   component: GameV2,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/jeu/archive/:id',
  //   name: 'gameArchive',
  //   props(route) {
  //     const props = { ...route.params };
  //     props.id = +props.id;
  //     return props;
  //   },
  //   component: Game,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/liste-de-noel',
    name: 'christmasList',
    component: ChristmasList,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/liste-de-naissance',
  //   name: 'wishlist',
  //   component: WishList,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/photos',
  //   name: 'photos',
  //   component: Photos,
  //   props: {
  //     message: 'Les photos de grossesse ...',
  //     version: '2',
  //     minMonth: 3,
  //     maxMonth: 8,
  //     showArchive: true
  //   },
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      isAdmin: true
    }
  },
  {
    path: '/mon-compte',
    name: 'myaccount',
    component: MyAccount,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mdp-oublie/:token',
    name: 'mdpoublie',
    props: true,
    component: ResetPassword,
    meta: {
      guest: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const userAuthString = localStorage.getItem('user');
  let userAuth = null;
  let authentified = false;
  if (userAuthString) {
    userAuth = JSON.parse(userAuthString);
    if (userAuth && userAuth.auth) authentified = true;
  }

  if (authentified && userAuth.token) {
    checkJWT(userAuth.token);
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !authentified) {
    next('/');
  } else if (to.path === '/' && authentified) {
    next('/accueil');
  } else next();
});

export default router;
