<template>
  <b-container fluid>
    <h3>Solutions :</h3>
    <br />
    <b-row>
      <b-col
        xs="12"
        md="4"
        xl="3"
        v-for="(game, i) in this.editedGames"
        :key="game.number"
      >
        <b-badge
          :variant="
            game.solution === null
              ? 'secondary'
              : game.solution !== games[i].solution
              ? 'warning'
              : 'primary'
          "
          ><b-row>
            <b-col cols="6">#{{ game.number }} - {{ game.type }} :</b-col
            ><b-col cols="3">
              <b-form-input
                style="padding:0.5rem"
                type="text"
                v-model="game.solution"
              ></b-form-input></b-col
            ><b-col cols="3"
              ><b-button
                v-if="game.solution != games[i].solution"
                @click.prevent="saveGameSolution(game)"
                >OK</b-button
              ><b-button
                v-else-if="game.solution"
                @click.prevent="deleteGameSolution(game)"
                >X</b-button
              ></b-col
            ></b-row
          ></b-badge
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GameService from '@/service/GameService';

export default {
  name: 'GameSolutionAdmin',
  data() {
    return {
      games: [],
      solutions: [],
      editedGames: []
    };
  },
  computed: {},
  mounted() {
    this.games = GameService.allGames_v2;
    this.refreshGameSolutions();
  },
  methods: {
    refreshGameSolutions() {
      GameService.getAllSolutions().then(res => {
        this.solutions = res.data.data;
        this.games.forEach(g => {
          const s = this.solutions.find(_ => _.id === g.id);
          g.solution = s ? s.solution : null;
        });
        this.editedGames = this.games.map(a => {
          return { ...a };
        });
      });
    },
    saveGameSolution(game) {
      GameService.saveSolution(game.id, game.number, game.solution).then(() => {
        this.refreshGameSolutions();
      });
    },
    deleteGameSolution(game) {
      GameService.deleteSolution(game.id).then(() => {
        this.refreshGameSolutions();
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
