<template>
  <div>
    <span class="ml-auto" style="z-index:1;">
      <b-button
        id="btn-full-view"
        size="sm"
        class="ml-1"
        variant="info"
        @click="showInactive = !showInactive"
        >{{
          showInactive
            ? 'Masquer les comptes inactifs'
            : 'Afficher les comptes inactifs'
        }}</b-button
      >
      <b-button
        id="btn-full-view"
        size="sm"
        class="ml-1"
        variant="info"
        @click="showAllFields = !showAllFields"
        >{{
          showAllFields ? 'Affichage simple' : 'Affichage complet'
        }}</b-button
      ><b-button
        id="btn-order-done"
        size="sm"
        class="ml-1"
        variant="warning"
        v-show="hasModifications"
        @click="saveModifications"
        >Sauvegarder les modifications</b-button
      ><b-button
        id="btn-order-cancel"
        size="sm"
        class="ml-1"
        variant="secondary"
        v-show="hasModifications"
        @click="cancelModifications"
        >Annuler les modifications</b-button
      ></span
    >
    <br />
    <br />
    <b-table
      striped
      hover
      :items="filteredUsers"
      :fields="fields"
      :tbody-tr-class="rowClass"
      @row-clicked="selectUser"
    >
      <template v-slot:cell(active)="data">
        <span
          :class="
            data.value
              ? 'icon icon-checkbox-checked'
              : 'icon icon-checkbox-unchecked'
          "
        ></span>
      </template>
      <template v-slot:cell(admin)="data">
        <span
          :class="
            data.value
              ? 'icon icon-checkbox-checked'
              : 'icon icon-checkbox-unchecked'
          "
        ></span>
      </template>
      <template v-slot:cell(date_last_connected)="data">
        {{
          data.value !== null && data.value !== ''
            ? new Date(data.value).toLocaleString('fr-FR')
            : ''
        }}
      </template>
      <template v-slot:cell(date_last_action)="data">
        {{
          data.value !== null && data.value !== ''
            ? new Date(data.value).toLocaleString('fr-FR')
            : ''
        }}
      </template>
      <template v-slot:cell(logs)="data">
        <div v-for="(action, i) in data.value" :key="i">
          {{ new Date(action.date).toLocaleString('fr-FR') }} :
          {{ action.action }}
        </div>
      </template>
      <template v-slot:cell(tags)="row">
        <b-form-tags
          input-id="tags-basic"
          v-model="row.value"
          @input="updateTags(row.item.id, row.value)"
        ></b-form-tags>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          v-if="row.item.active"
          @click="deActivateUser(row.item.id)"
          >Désactiver</b-button
        >
        <b-button size="sm" v-else @click="activateUser(row.item.id)"
          >Activer</b-button
        >
      </template>
    </b-table>
    <b-modal
      id="modal-selected-user"
      :title="selectedUser?.first_name + ' ' + selectedUser?.last_name"
      centered
      hide-footer
      content-class="shadow"
      :header-bg-variant="selectedUser?.active ? 'info' : 'secondary'"
    >
      <b-overlay :show="loadingUser" rounded="sm"
        ><b-container>
          <p>ID : {{ selectedUser?.id }}</p>
          <p>Email : {{ selectedUser?.email }}</p>
          <p>Username : {{ selectedUser?.user_name }}</p>
          <p>
            Actif :
            <span
              :class="
                selectedUser?.active
                  ? 'icon icon-checkbox-checked'
                  : 'icon icon-checkbox-unchecked'
              "
            ></span>
          </p>
          <p>
            Admin :
            <span
              :class="
                selectedUser?.admin
                  ? 'icon icon-checkbox-checked'
                  : 'icon icon-checkbox-unchecked'
              "
            ></span>
          </p>
          <p>
            Dernière connexion :
            {{
              selectedUser?.date_last_connected
                ? new Date(selectedUser?.date_last_connected).toLocaleString(
                    'fr-FR'
                  )
                : ''
            }}
          </p>
          <p>Dernières actions :</p>
          <ul>
            <li v-for="(action, i) in selectedUser?.logs" :key="i">
              {{ new Date(action.date).toLocaleString('fr-FR') }} :
              {{ action.label }}
            </li>
          </ul>
        </b-container></b-overlay
      ></b-modal
    >
  </div>
</template>

<script>
import UserService from '@/service/UserService';

export default {
  name: 'userAdmin',
  props: {
    users: Array,
    allTags: Array
  },
  watch: {
    users(newVal) {
      this.usersToEdit = JSON.parse(JSON.stringify(newVal));
      this.originalTags = newVal.map(u => {
        return {
          id: u.id,
          tags: u.tags
        };
      });
    }
  },
  data() {
    return {
      showInactive: false,
      showAllFields: false,
      loadingUser: false,
      originalTags: [],
      usersToEdit: [],
      selectedUser: null,
      allFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'user_name', label: 'Identifiant', sortable: true },
        {
          key: 'first_name',
          label: 'Prénom',
          sortable: true,
          simpleView: true
        },
        { key: 'last_name', label: 'Nom', sortable: true, simpleView: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'active', label: 'Actif', sortable: true },
        { key: 'admin', label: 'Admin', sortable: true },
        { key: 'tags', label: 'Tags' },
        {
          key: 'date_last_action',
          label: 'Dernière action',
          sortable: true,
          simpleView: true
        },
        {
          key: 'date_last_connected',
          label: 'Dernière connexion',
          sortable: true
        },
        { key: 'logs', label: 'Dernières actions' },
        { key: 'actions', label: '', simpleView: true }
      ]
    };
  },
  mounted() {
    this.usersToEdit = JSON.parse(JSON.stringify(this.users));
    this.originalTags = this.users.map(u => {
      return {
        id: u.id,
        tags: u.tags
      };
    });
  },
  computed: {
    modifications() {
      return this.usersToEdit.filter((u, i) => {
        return u.tags.join(',') !== this.users[i].tags.join(',');
      });
    },
    hasModifications() {
      return this.modifications.length > 0;
    },
    filteredUsers() {
      return this.usersToEdit.filter(u => {
        return u.active || this.showInactive;
      });
    },
    fields() {
      let fields;
      if (!this.showAllFields)
        fields = this.allFields.filter(f => f.simpleView);
      else fields = this.allFields;
      return fields;
    },
    newTags() {
      const foundTags = this.modifications
        .map(u => u.tags)
        .flat()
        .sort()
        .filter((x, i, a) => !i || x != a[i - 1]);
      return foundTags.filter(t => {
        return this.allTags.indexOf(t) < 0;
      });
    },
    tagDeleted() {
      const foundTags = this.modifications
        .map(u => u.tags)
        .flat()
        .sort()
        .filter((x, i, a) => !i || x != a[i - 1]);
      return (
        this.allTags.filter(t => {
          return foundTags.indexOf(t) < 0;
        }).length > 0
      );
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (!item.active) return 'table-secondary';
    },
    activateUser(userId) {
      UserService.activate(userId).then(() => {
        this.$emit('refreshAsked');
      });
    },
    deActivateUser(userId) {
      UserService.deactivate(userId).then(() => {
        this.$emit('refreshAsked');
      });
    },
    updateTags(userId, tags) {
      const user = this.usersToEdit.find(u => u.id === userId);
      user.tags = tags;
    },
    saveModifications() {
      const payload = {
        users: this.modifications,
        newTags: this.newTags,
        tagDeleted: this.tagDeleted
      };
      UserService.updateList(payload).then(() => {
        this.$emit('refreshAsked');
      });
    },
    cancelModifications() {
      this.$emit('refreshAsked');
    },
    selectUser(item) {
      this.loadingUser = true;
      this.$bvModal.show('modal-selected-user');
      UserService.getUserData(item.id)
        .then(res => {
          this.selectedUser = res.data;
        })
        .catch(err => {
          this.errorMessage = err.response.data.error;
        })
        .finally(() => {
          this.loadingUser = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
