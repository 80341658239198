import { postToGameService } from './apiClient';

export default {
  allGames_v1: [
    { id: 1, type: 'Mots croisés' },
    { id: 2, type: 'Chiffres ...' },
    { id: 3, type: 'Suite logique' },
    { id: 4, type: 'Grille' },
    {
      id: 5,
      type: 'Photos',
      clue:
        "Au fait, j'ai retrouvé ces jeux dans le désordre, donc les lettres doivent être un peu mélangées ..."
    },
    {
      id: 6,
      type: 'Suite logique',
      clue:
        'Vous attendiez un U ?? Non, je ne suis pas Samuel. Aidez-moi à retrouver tous mes prénoms ...'
    },
    { id: 7, type: 'Enigme' },
    { id: 8, type: 'Enigme' },
    { id: 9, type: 'Suite logique' },
    { id: 10, type: 'Photo' },
    { id: 11, type: 'Enigme' },
    { id: 12, type: 'Suite logique' },
    { id: 13, type: 'Enigme' },
    { id: 14, type: 'Mémoire' },
    { id: 15, type: 'Enigme' },
    { id: 16, type: 'Photo' },
    {
      id: 17,
      type: 'Mots mêlés'
    }
  ],
  allGames_v2: [
    {
      id: 18,
      number: 1,
      type: 'Sudoku'
    },
    {
      id: 19,
      number: 2,
      type: 'Mots croisés'
    },
    {
      id: 20,
      number: 3,
      type: 'Enigme'
    },
    {
      id: 21,
      number: 4,
      type: 'Simon'
    },
    {
      id: 22,
      number: 5,
      type: 'Code de Papa'
    },
    {
      id: 23,
      number: 6,
      type: 'Coffre-fort',
      clue:
        "Vous attendiez peut-être un E pour Amélie ou un N pour Mélina ... Raté&nbsp;! Comme c'était le cas pour Matteo, il faut trouver les 3 prénoms de bébé chou&nbsp;!"
    },
    {
      id: 24,
      number: 7,
      type: 'QCM'
    },
    {
      id: 25,
      number: 8,
      type: 'Devinette'
    },
    {
      id: 26,
      number: 9,
      type: 'Enigme'
    },
    {
      id: 27,
      number: 10,
      type: 'Bataille navale'
    },
    {
      id: 28,
      number: 11,
      type: 'Photo'
    },
    {
      id: 29,
      number: 12,
      type: 'Démineur',
      clue:
        'Les 3 prénoms de bébé chou se terminent tous par un A, et le premier est plus long que les autres.'
    },
    {
      id: 30,
      number: 13,
      type: 'Photos'
    },
    {
      id: 31,
      number: 14,
      type: 'Jouets'
    },
    {
      id: 32,
      number: 15,
      type: 'Mots mêlés'
    },
    {
      id: 33,
      number: 16,
      type: 'Dessin'
    },
    {
      id: 34,
      number: 17,
      type: 'Une grosse journée'
    },
    {
      id: 35,
      number: 18,
      type: 'Memory'
    }
  ],
  saveAnswer(userId, gameId, answer, version) {
    return postToGameService('/saveAnswer', {
      userId,
      gameId,
      answer,
      version
    });
  },
  getMyAnswers(userId, version) {
    return postToGameService('/getMyAnswers', { userId, version });
  },
  getAllSolutions() {
    return postToGameService('/solutions');
  },
  saveSolution(id, number, solution) {
    return postToGameService('/savesolution', { id, number, solution });
  },
  deleteSolution(id) {
    return postToGameService('/deletesolution', { id });
  }
};
