import axios from 'axios';
const dotenv = require('dotenv');
dotenv.config();
import store from '../store/index';
import { disconnectUser } from '../service/authUtils';

// INTERCEPTORS
function handleResponseOK(res) {
  if (res.status === 200 || res.status === 201) return Promise.resolve(res);
  else return Promise.reject(res);
}

function handleResponseError(err) {
  if (err.response.status === 401) {
    disconnectUser(err.response.data.reason);
  }
  return Promise.reject(err);
}

function _createApi(path, withAuth) {
  const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL + path,
    withCredentials: withAuth,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
  if (withAuth) {
    client.interceptors.response.use(
      res => {
        return handleResponseOK(res);
      },
      err => {
        return handleResponseError(err);
      }
    );
  }
  return client;
}

const apiClientForAuth = _createApi('/auth', false);

const apiClientForUser = _createApi('/user', true);

const apiClientForGame = _createApi('/game', true);

const apiClientForWishlist = _createApi('/wishlist', true);

function postToAuthService(path, params) {
  return apiClientForAuth.post(path, params);
}
function postToUserService(path, params) {
  return apiClientForUser.post(path, params, {
    headers: {
      Authorization: 'Bearer ' + store.getters.jwt
    }
  });
}
function putToUserService(path, params) {
  return apiClientForUser.put(path, params, {
    headers: {
      Authorization: 'Bearer ' + store.getters.jwt
    }
  });
}
function deleteToUserService(path, params) {
  return apiClientForUser.delete(path, params, {
    headers: {
      Authorization: 'Bearer ' + store.getters.jwt
    }
  });
}
function postToGameService(path, params) {
  return apiClientForGame.post(path, params, {
    headers: {
      Authorization: 'Bearer ' + store.getters.jwt
    }
  });
}
function postToWishlistService(path, params) {
  return apiClientForWishlist.post(path, params, {
    headers: {
      Authorization: 'Bearer ' + store.getters.jwt
    }
  });
}

export {
  postToAuthService,
  postToUserService,
  putToUserService,
  deleteToUserService,
  postToGameService,
  postToWishlistService
};
