<template>
  <div>
    <span class="ml-auto" style="z-index:1;">
      <b-button
        id="btn-full-view"
        size="sm"
        class="ml-1"
        variant="info"
        @click="showInactive = !showInactive"
        >{{
          showInactive
            ? 'Masquer les comptes inactifs'
            : 'Afficher les comptes inactifs'
        }}</b-button
      >
      <b-button
        id="btn-full-view"
        size="sm"
        class="ml-1"
        variant="info"
        @click="showAllFields = !showAllFields"
        >{{
          showAllFields ? 'Affichage simple' : 'Affichage complet'
        }}</b-button
      ><b-button
        id="btn-order-done"
        size="sm"
        class="ml-1"
        variant="warning"
        v-show="hasModifications"
        @click="saveModifications"
        >Sauvegarder les modifications</b-button
      ><b-button
        id="btn-order-cancel"
        size="sm"
        class="ml-1"
        variant="secondary"
        v-show="hasModifications"
        @click="cancelModifications"
        >Annuler les modifications</b-button
      ></span
    >
    <br />
    <br />
    <b-table
      striped
      hover
      :items="displayedUsers"
      :fields="fields"
      :tbody-tr-class="rowClass"
    >
      <template v-slot:cell(games)="row">
        <b-form-spinbutton
          :value="row.value"
          :max="maxGame"
          inline
          @change="setGameAccess(row.item.id, $event)"
        ></b-form-spinbutton>
      </template>
      <template v-slot:cell(results)="row">
        <b-button
          class="mx-1"
          :variant="
            result.answer
              ? result.is_correct
                ? 'success'
                : 'danger'
              : 'secondary'
          "
          v-for="result in row.value"
          :key="result.number"
        >
          #{{ result.number }}
          <b-badge variant="light">{{ result.answer }}</b-badge>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import UserService from '@/service/UserService';
import GameService from '@/service/GameService';

export default {
  name: 'GameAccessAdmin',
  props: {
    users: Array
  },
  watch: {
    users() {
      this.originalUserGameList = [];
      this.users.forEach(u => {
        this.originalUserGameList.push({
          id: u.id,
          games: u.games
        });
      });
    }
  },
  data() {
    return {
      originalUserGameList: [],
      showInactive: false,
      showAllFields: false,
      games: [],
      allFields: [
        { key: 'id', label: 'ID', sortable: true, simpleView: false },
        {
          key: 'user_name',
          label: 'Identifiant',
          sortable: true,
          simpleView: false
        },
        {
          key: 'first_name',
          label: 'Prénom',
          sortable: true,
          simpleView: true
        },
        { key: 'last_name', label: 'Nom', sortable: true, simpleView: true },
        { key: 'score', label: 'Score', sortable: true, simpleView: true },
        { key: 'games', label: 'Jeux', sortable: true, simpleView: true },
        {
          key: 'results',
          label: 'Résultats',
          sortable: false,
          simpleView: true
        }
      ]
    };
  },
  computed: {
    maxGame() {
      return Math.max(...this.games.map(g => g.number));
    },
    fields() {
      let fields;
      if (!this.showAllFields)
        fields = this.allFields.filter(f => f.simpleView);
      else fields = this.allFields;
      return fields;
    },
    modifications() {
      const modifs = [];
      if (this.originalUserGameList.length > 0) {
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].games !== this.originalUserGameList[i].games) {
            modifs.push({
              id: this.users[i].id,
              games: this.users[i].games
            });
          }
        }
      }
      return modifs;
    },
    hasModifications() {
      return this.modifications.length > 0;
    },
    filteredUsers() {
      return this.users.filter(u => {
        return u.active || this.showInactive;
      });
    },
    displayedUsers() {
      const tmp = this.filteredUsers;
      tmp.forEach(u => {
        if (!u.active) u._rowVariant = 'secondary';
        u.score = u.games_answers.filter(a => {
          return a.website_version == 2 && a.is_correct;
        }).length;
        const answers = u.games_answers.filter(a => {
          return a.website_version == 2;
        });
        const results = [];
        if (answers) {
          this.games.forEach(g => {
            if (g.number <= u.games) {
              const answerFound = answers.find(a => {
                return a.game_id === g.id;
              });
              const gameResult = {
                number: g.number,
                displayedVariant: 'secondary'
              };
              if (answerFound) {
                gameResult.answer = answerFound.answer;
                gameResult.is_correct = answerFound.is_correct;
                gameResult.displayedVariant = '';
              }
              results.push(gameResult);
            }
          });
        }
        u.results = results;
      });
      return tmp;
    }
  },
  mounted() {
    this.games = GameService.allGames_v2;
  },
  methods: {
    setGameAccess(id, value) {
      const user = this.users.find(u => {
        return u.id === id;
      });
      user.games = value;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (!item.active) return 'table-secondary';
    },
    saveModifications() {
      UserService.updateList({ users: this.modifications }).then(() => {
        this.$emit('refreshAsked');
      });
    },
    cancelModifications() {
      this.$emit('refreshAsked');
    }
  }
};
</script>

<style lang="scss" scoped></style>
