<template>
  <div>
    <span class="ml-auto" style="z-index:1;">
      <b-button
        id="btn-full-view"
        size="sm"
        class="ml-1"
        variant="info"
        @click="showAllFields = !showAllFields"
        >{{
          showAllFields ? 'Affichage simple' : 'Affichage complet'
        }}</b-button
      ><b-button
        id="btn-order-start"
        size="sm"
        class="ml-1"
        variant="primary"
        v-show="!modeUpdateOrder"
        @click="startUpdateOrder()"
        >Modifier l'ordre</b-button
      >
      <b-button
        id="btn-order-done"
        size="sm"
        class="ml-1"
        variant="warning"
        v-show="modeUpdateOrder"
        @click="stopUpdateOrder()"
        >Sauvegarder l'ordre</b-button
      >
      <b-button
        id="btn-order-cancel"
        size="sm"
        class="ml-1"
        variant="secondary"
        v-show="modeUpdateOrder"
        @click="cancelUpdateOrder()"
        >Annuler les modifications</b-button
      >
    </span>
    <br />
    <br />
    <b-table
      ref="wishListTable"
      striped
      hover
      :items="itemList"
      :fields="fields"
      @row-clicked="rowClicked"
      :sort-by.sync="mySortBy"
      :sort-desc.sync="mySortDesc"
      tbody-tr-class="wishlist-row"
    >
      <template v-slot:cell(description)="data">
        {{
          data.value.length > 20
            ? data.value.substring(0, 20) + '...'
            : data.value
        }}
      </template>
      <template v-slot:cell(price)="data">{{
          formatPrice(data.value)
        }}</template>
      <template v-slot:cell(date_bought)="data">{{
        data.value ? new Date(data.value).toLocaleString('fr-FR') : ''
      }}</template>
      <template v-slot:cell(available)="data">
        <span
          :class="
            data.value
              ? 'icon icon-checkbox-unchecked'
              : 'icon icon-checkbox-checked'
          "
        ></span>
      </template>
      <template v-slot:cell(bought_by)="data">
        {{
          data.value !== null && data.value !== ''
            ? data.value.first_name +
              ' ' +
              data.value.last_name +
              ' (' +
              data.value.user_name +
              ')'
            : ''
        }}
      </template>
      <template v-slot:cell(actions)="row" v-if="modeUpdateOrder">
        <b-button
          size="sm"
          v-if="row.item.position > 1"
          @click="moveUp(row.item)"
        >
          <span class="icon icon-arrow-up"></span>
        </b-button>
        <b-button
          size="sm"
          v-if="row.item.position < itemList.length"
          @click="moveDown(row.item)"
        >
          <span class="icon icon-arrow-down"></span>
        </b-button>
      </template>
    </b-table>
    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      id="modal-edit-item"
      title="Modifier un objet"
      centered
      hide-footer
      content-class="shadow"
    >
      <b-form @submit.prevent="saveItem()">
        <b-form-group id="input-group-url" label="URL" label-for="input-url">
          <b-form-input
            id="input-url"
            v-model="itemToUpdate.url"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            id="input-group-recipient"
            label="Pour"
            label-for="input-recipient"
        >
          <b-form-input
              id="input-recipient"
              v-model="itemToUpdate.recipient"
              type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            id="input-group-title"
            label="Titre"
            label-for="input-title"
        >
          <b-form-input
              id="input-title"
              v-model="itemToUpdate.title"
              type="text"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-description"
          label="Description"
          label-for="input-title"
        >
          <b-form-input
            id="input-description"
            v-model="itemToUpdate.description"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-price"
          label="Prix"
          label-for="input-price"
        >
          <b-form-input
            id="input-price"
            v-model="itemToUpdate.price"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-image"
          label="Image"
          label-for="input-title"
        >
          <b-form-input
            id="input-image"
            v-model="itemToUpdate.image"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-row>
          <div class="ml-auto">
            <b-button
              type="reset"
              variant="secondary"
              class="mx-1"
              @click.prevent="handleCancel()"
              >Annuler</b-button
            >
            <b-button type="submit" name="button" variant="info" class="mx-1"
              >Sauvegarder les modifications</b-button
            >
          </div>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import WishListService from '@/service/WishListService';

export default {
  name: 'wishListAdmin',
  data() {
    return {
      mySortBy: '',
      mySortDesc: '',
      showAllFields: false,
      allFields: [
        {
          key: 'displayPosition',
          label: '#',
          sortable: false,
          simpleView: false
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          simpleView: false
        },
        {
          key: 'position',
          label: 'Ordre',
          sortable: true,
          simpleView: true
        },
        {
          key: 'recipient',
          label: 'Pour',
          sortable: true,
          simpleView: true
        },
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
          simpleView: true
        },
        {
          key: 'shop',
          label: 'Boutique',
          sortable: true,
          simpleView: false
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          simpleView: false
        },
        {
          key: 'price',
          label: 'Prix',
          sortable: true,
          simpleView: true
        },
        {
          key: 'available',
          label: 'Acheté',
          sortable: true,
          simpleView: true
        },
        {
          key: 'date_bought',
          label: 'Acheté le',
          sortable: true,
          simpleView: true
        },
        {
          key: 'bought_by',
          label: 'Par',
          sortable: true,
          simpleView: false
        },
        { key: 'actions', label: 'Actions', simpleView: true, sortable: false }
      ],
      itemList: [],
      itemToUpdate: {
        url: '',
        recipient: '',
        title: '',
        description: '',
        price: '',
        image: ''
      },
      modeUpdateOrder: false
    };
  },
  computed: {
    fields() {
      let fields;
      if (!this.showAllFields)
        fields = this.allFields.filter(f => f.simpleView);
      else fields = this.allFields;
      if (!this.modeUpdateOrder)
        fields = fields.filter(f => f.key !== 'actions');
      return fields;
    }
  },
  created() {
    this.refreshList();
  },
  methods: {
    refreshList() {
      WishListService.getChristmasList().then(response => {
        this.itemList = response.data.data;
        let displayPosition = 1;
        this.itemList.forEach(item => {
          item.displayPosition = displayPosition++;
          item.originalPosition = item.position;
        });
      });
    },
    startUpdateOrder() {
      this.mySortBy = 'position';
      this.mySortDesc = false;
      this.modeUpdateOrder = true;
    },
    stopUpdateOrder() {
      let items = [];
      this.itemList.forEach(item => {
        items.push({ id: item.id, newPosition: item.position });
      });
      WishListService.updateOrder(items).then(() => {
        this.refreshList();
        this.modeUpdateOrder = false;
      });
    },
    cancelUpdateOrder() {
      this.itemList.forEach(item => {
        item.position = item.originalPosition;
      });
      this.modeUpdateOrder = false;
    },
    formatPrice(price) {
      if (!price) return '--,-- €';
      let cur = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      });
      return cur.format(price);
    },
    newEmptyItem() {
      return {
        url: '',
        recipient: '',
        title: '',
        description: '',
        price: '',
        image: ''
      };
    },
    rowClicked(item) {
      this.itemToUpdate = item;
      this.$bvModal.show('modal-edit-item');
    },
    handleCancel() {
      this.itemToUpdate = this.newEmptyItem();
      this.$bvModal.hide('modal-edit-item');
    },
    saveItem() {
      WishListService.updateItem(this.itemToUpdate).then(() => {
        this.itemToUpdate = this.newEmptyItem();
        this.$bvModal.hide('modal-edit-item');
      });
    },
    moveUp(selectedItem) {
      let newPosition = selectedItem.position - 1;
      this.itemList.forEach(item => {
        if (item.position === newPosition) item.position++;
      });
      selectedItem.position = newPosition;
      this.$refs.wishListTable.refresh();
    },
    moveDown(selectedItem) {
      let newPosition = selectedItem.position + 1;
      this.itemList.forEach(item => {
        if (item.position === newPosition) item.position--;
      });
      selectedItem.position = newPosition;
      this.$refs.wishListTable.refresh();
    }
  }
};
</script>

<style>
tr.wishlist-row {
  cursor: pointer;
}
</style>
